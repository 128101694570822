.account-button {
  margin: 10px;
  color: #932225 !important;
  background-color: #FFFFFF !important;
  border-color: #FFFFFF !important;
}

.account-button:hover {
  background-color: #FFFFFF !important;
  border-color: #FFFFFF !important;
}

.navbar-desktop {
  background: rgb(220,49,55);
  background: -moz-linear-gradient(top, rgba(220,49,55,1) 0%, rgba(147,34,37,1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220,49,55,1)), color-stop(100%,rgba(147,34,37,1)));
  background: -webkit-linear-gradient(top, rgba(220,49,55,1) 0%,rgba(147,34,37,1) 100%);
  background: -o-linear-gradient(top, rgba(220,49,55,1) 0%,rgba(147,34,37,1) 100%);
  background: -ms-linear-gradient(top, rgba(220,49,55,1) 0%,rgba(147,34,37,1) 100%);
  background: linear-gradient(to bottom, rgba(220,49,55,1) 0%,rgba(147,34,37,1) 100%);

  @media screen and (max-width: 786px) {
    margin-top: 10px !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  @media screen and (min-width: 786px) {
    align-items: center !important;
    justify-content: space-between !important;
  }
}
