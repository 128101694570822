/*Copyright © 2020 Cloudcontainers b.v. - All Rights Reserved*/

@import "./css/vars";

.App-container {
  min-height: 100vh;
  padding: 0px;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #474442;
}

.App-navbar {
  background-color: #4485bc;
  color: white;
  font-family: effra, sans-serif;
  font-weight: 400;
  min-height: 3.5rem;
}

.App-footer {
  background: -moz-linear-gradient(top, rgba(221,221,221,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(221,221,221,1)), color-stop(100%,rgba(255,255,255,1) ));
  background: -webkit-linear-gradient(top, rgba(221,221,221,1) 0%,rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(top, rgba(221,221,221,1) 0%,rgba(255,255,255,1) 100%);
  background: -ms-linear-gradient(top, rgba(221,221,221,1) 0%,rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(221,221,221,1) 0%,rgba(255,255,255,1) 100%);
  color: #333333;
}

.discover-link {
  text-decoration: none !important;
  color: #4485bc !important;
}

.dropdown-link {
  text-decoration: none !important;
  color: #4485bc !important;
}

.dropdown-item:active {
  text-decoration: none !important;
  color: white !important;
  background-color: #4485bc !important;
}

.my-link {
  text-decoration: underline;
  color: #333333 !important;
}

.nav-title {
  font-size: larger;
}

.Title-col {
  background-color: #4485bc;
  color: white;
  border-style: solid;
  border-width: 1px;
  border-color: white;
}

.title-description {
  font-size: 1.5em;
}

.tile-margin {
  margin-bottom: auto !important;
}

.effra-text {
  font-family: effra, sans-serif;
}

.source-code-text {
  font-family: 'Source Code Pro', monospace;
  font-size: x-large !important;
}

.Initworks-logo {
  max-width: 200px;
  padding-bottom: 4px;
  object-fit: contain
}

.Initworks-man {
  max-width: 50px;
  padding-bottom: 4px;
  object-fit: contain
}

.sys-logo {
  height: 24px;
  object-fit: contain;
}

.blue-link {
  color: #4485bc !important;
}

.Initworks-thumbs-up {
  height: 80px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.text-link-hover:hover {
  border-top: 0px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 0px;
  border-style: solid;
  border-color: white;
}

.text-link {
  border-top: 0px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 0px;
  border-style: solid;
  border-color: white;
}

.title-text {
  font-size: 2.5rem;
  font-weight: bolder;
  font-family: effra, sans-serif;
}

.disabled {
  pointer-events: none;
}

.a {
  text-decoration: none;
}

p {
  margin: 0 !important;
}

.svg-middle {
  vertical-align: middle;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.FadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
       -o-animation: fadein 1s; /* Opera < 12.1 */
          animation: fadein 1s;
}

.Main-col {
  margin-top: 0.5rem !important;
  background-color: white;
  border: 1px;
  border-style: solid;
  border-color: #dbdbdb;
}

@media only screen and (min-width: $md-breakpoint)
  {
  .Main-col {
    margin-top: -5rem !important;
  }
}

.Minimal-col {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  background-color: white;
  border: 1px;
  border-style: solid;
  border-color: #dbdbdb;
}

.btn-primary {
  color: #ffffff;
  margin: 10px;
  background: rgb(220,49,55);
  background: -moz-linear-gradient(top, rgba(220,49,55,1) 0%, rgba(147,34,37,1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220,49,55,1)), color-stop(100%,rgba(147,34,37,1)));
  background: -webkit-linear-gradient(top, rgba(220,49,55,1) 0%,rgba(147,34,37,1) 100%);
  background: -o-linear-gradient(top, rgba(220,49,55,1) 0%,rgba(147,34,37,1) 100%);
  background: -ms-linear-gradient(top, rgba(220,49,55,1) 0%,rgba(147,34,37,1) 100%);
  background: linear-gradient(to bottom, rgba(220,49,55,1) 0%,rgba(147,34,37,1) 100%);
  border-color: #cccccc !important;
}

.btn-outline-primary {
  color: #4485bc !important;
  border-color: #4485bc !important;
}

.btn-primary:hover {
  background-color: #366b99 !important;
  border-color: #366b99 !important;
}

.btn-outline-primary:hover {
  color: #4485bc !important;
  background-color: white !important;
  border-color: #366b99 !important;
}

.btn-outline-primary:hover {
  color: #4485bc !important;
  background-color: white !important;
  border-color: #366b99 !important;
}

.btn-secondary {
  color: #932225 !important;
  border: none !important;
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(221,221,221,1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(221,221,221,1)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(221,221,221,1) 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(221,221,221,1) 100%);
  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(221,221,221,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(221,221,221,1) 100%);
}

.load-prog {
  background-color: #4485bc !important;
}

.crypt-prog {
  background-color: #ffc107 !important;
}

.break-on-overflow {
  display: inline-block;
  word-break: break-word;
}
