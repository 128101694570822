//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved
// Breakpoints
$sm-breakpoint: 576px;
$md-breakpoint: 768px;
$lg-breakpoint: 992px;
$xl-breakpoint: 1200px;

// Nav
$nav-link-padding-y: .5rem;

// Colors
$theme-colors: (
	primary: #4485bc,
	info: #8f5f9b,
	secondary: #dbdbdb,
	dark: #474442,
	danger: #e7503d,
	success: #00d827,
	start: #7bcad1
);

$border-color: #dbdbdb;
$body-color: #474442;

// Tiles
$tile-padding: 10px;

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}