.my-header {
  background: url('../media/images/brainpoint-banner.jpg') no-repeat center top;
  height: 10rem;
}

/* MD - medium tablet */
/* SM - small phone */
/* XS - extra small phone */
@media (max-width: 991px) {

  .my-header {
    background: url('../media/images/brainpoint-banner-center.jpg') no-repeat center top;
  }

}

/* SM - small phone */
/* XS - extra small phone */
@media (max-width: 768px) {

  .my-header {
    height: 5rem;
  }

}

/* SM - small phone */
/* XS - extra small phone */
@media (max-width: 575px) {

  .my-header {
    background-size: 115%;
  }

}
